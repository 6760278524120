import React from "react";
import Navbar from "../NavBar";
import Footer from "../Footer";
import Project_1 from "./Project_1";
import Project_2 from "./Project_2";
import Project_3 from "./Project_3";



const AboutUs = () => {
  return (
    <div>
        <Navbar />
        <Project_1/>
        <Project_2/>
        <Project_3/>
        <Footer/>

    </div>
  );
};

export default AboutUs;